import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { TextContainer } from './common'
// import { useImagesPhilosophy } from "../hooks/use-images-philosophy"
// import Img from "gatsby-image"


// import { Link } from "gatsby"



const Bg = styled.div`
   width: 100%;
   height: 100%;
   transition: 1s;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 10;
   
   ${p => p.open ? css`
      visibility: visible;
      background-color: rgba(0,0,0,0.6);     
  ` : css`
      background-color: rgba(0,0,0,0);
      visibility: hidden;
  `}
`

// @media (max-width: 600px) {
//     min-width: 300px;
//     width: 90%;
//     min-height: 320px;
//     height: 60%;
//     position: absolute;
//     margin: 0;
//     top: 50%;
//     left: 50%;
//     -ms-transform: translate(-50%, -50%);
//     transform: translate(-50%, -50%);

// }

const Nl_window_big = styled.div`
    width: 86%;
    min-width: 370px;
    height: 90%;
    min-height: 420px;
    margin: auto;
    background-color: white;
    margin-top: 2.5%;
    padding: 1rem;

    @media (max-width: 600px) {
        margin-top: 5%;
        width: 90%
    }

    ${p => p.open ? css`
      visibility: visible;
  ` : css`
      visibility: hidden;
  `}

`

// @media (max-width: 600px) {
//     min-width: 280px;
//     min-height: 300px;

// }
const Nl_window = styled.div`
    width: 100%;
    min-width: 350px;
    height: 100%;
    min-height: 400px;
    margin: auto;
    background-color: white;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 2px solid #214c9b;
    position: relative;

    font-size: 1.6rem;

    h1{
        text-align: center;
        margin-top: 0;
    }
`
const CloseW = styled.div`
    float: right;
    position: relative;
    cursor: pointer;
    width: 48px;
    height: 48px;
    margin-right: -1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    ::before,
    ::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #214C9B;
    }
    ::before {
        transform: rotate(45deg);
    }
    ::after {
        transform: rotate(-45deg);
    }
`
 
const FormContainer = styled.div`
   
    width:  90%;
    color: #214c9b;
    margin: auto;

    input{
        @media (max-width: 700px) {
            font-size: 1.2rem;
            
        }
        @media (max-width: 600px) {
            width:  90%;
        }
    }  
`
const TextInput = styled.input`
    display: block;
    margin: auto; 
    border: 1px solid black; 
    color: black;
    outline: 0;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    padding: 0.6rem 1.2rem;
    width: 500px;
    @media (max-width: 700px) {
        width: 320px;
    }
`

const SubmitInput = styled.input`
    margin: auto;
    background-color: transparent;
    color: #666;
    padding: 3px  30px 3px 30px; 
    border: 2px solid #0099df;
    cursor: pointer;
    width: max-content;
    display: block;
    font-size: 1.3rem;
`

let timer
let done = false
let Images =  {}
var useremail = ""



const ConsulSign = (props) =>{
    // const [open, setOpen] = useState(props.open);

    const handleUpdate = (event) => {
        if(event.target.name == "email"){
            useremail = event.target.value
        }
    }

    // useEffect(() => {
    //     if(props.delay && !done){
    //         clearTimeout(timer);
    //         timer = setTimeout(() => {done=true;setOpen(true)}, props.delay*1000);
    //     }
       
    // });
    // Images = useImagesPhilosophy()


    return(
    
        <Bg open={props.open}>
            <Nl_window_big open={props.open}>
                <Nl_window>
                    <CloseW onClick={props.onClose}/>
                    {/* <TextContainer style={{width:'100%'}}> */}
                        <div style={{width:'100%', height: '86%', overflow:'auto'}}>
                            <h1 style={{visibility:'hidden', position:'absolute', top:'60px'}}>Our goal is to help immigrants remain in the United States with their families, overcome their fears, and gain legal status to live a more fulfilling life. Our Firm's experience, diversity, and commitment to the field of immigration law make us uniquely qualified to help people from all around the world achieve their immigration goals.</h1>
                            <h2 style={{margin:'auto', maxWidth:'90%', marginBottom:'2rem', textAlign:'left'}}>The value you receive: </h2>
                            <ul style={{margin:'auto', maxWidth:'80%', textAlign:'left', marginBottom:'2rem'}}>
                                <li>Ability to send your legal documents ahead of the consultation for the attorney to look at prior to your one-on-one session;</li>
                                <li>Dedicated one-on-one 45 minute consultation with our experienced attorney, where the attorney will listen to your concerns and fully assess your case;</li>
                                <li>If necessary, the attorney will conduct additional legal research, check court cases, federal laws, and speak with other experts;</li>
                                <li>Should the attorney deem it necessary, we will then schedule one follow-up 15 minute phone call explaining the attorney’s findings and any additional advice on how to proceed;</li>
                            </ul>

                            <h2 style={{margin:'auto', maxWidth:'90%', marginBottom:'2rem', textAlign:'left'}}>By the end of your consultation, you will:</h2>
                            <ul style={{margin:'auto', maxWidth:'80%', textAlign:'left', marginBottom:'3rem'}}>
                                <li>Have received a full review of all of your immigration options;</li>
                                <li>Understand whether the attorney believes you can apply for a particular benefit or overcome the immigration problem you are facing;</li>
                                <li>Get expert guidance on how to overcome your immigration challenge;</li>
                                <li>Be presented with an opportunity to retain our firm to help you with your case, or if necessary, be provided with a referral to a colleague or organization that specializes in solving your specific immigration challenges;</li>
                            </ul>
                            <h2 style={{margin:'auto', maxWidth:'90%', marginBottom:'2rem', textAlign:'center'}}>Contact us today and get the best assesment for your case</h2>
                            <h2 style={{margin:'auto', maxWidth:'90%', marginBottom:'1rem', textAlign:'center'}}>(718)-715-1667</h2>
                            {/* <p style={{margin:'auto', maxWidth:'80%', textAlign:'center'}}>Join our free monthly newsletter!</p>
                            <p style={{margin:'auto', maxWidth:'80%', textAlign:'center'}}> Stay informed of the latest changes in immigration law and find out more about the exciting work our firm does. </p>
                            <FormContainer> 
                                <form onSubmit={(evt)=>{evt.preventDefault();setOpen(false)}} style={{width:'100%', color:'#FDE3E3',marginTop:'2rem'}}>
                                <TextInput type="text" name="email" placeholder='your email' onChange={handleUpdate}/>
                                <SubmitInput style={{width:'max-content'}} type="submit" value="Join"/>
                                </form>
                            </FormContainer> */}
                        </div>
                    {/* </TextContainer> */}
                </Nl_window>
            </Nl_window_big>
        </Bg>   
       
    )
}

export default ConsulSign
