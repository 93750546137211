export const isBrowser = () => typeof window !== "undefined"

export const getMenuItem = () =>
  isBrowser() && window.localStorage.getItem("MenuItem")
    ? JSON.parse(window.localStorage.getItem("MenuItem"))
    : {}

export const setMenuItem = (scrollArgs) =>{
  if(isBrowser()){
    window.localStorage.setItem("MenuItem", JSON.stringify(scrollArgs))
  }
}

  