import React, {useState, useEffect} from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import scrollTo from 'gatsby-plugin-smoothscroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronUp} from '@fortawesome/free-solid-svg-icons'


const BtnUp = styled.button`
    background-color: rgba(0,153,223,0.2);
    border: none;
    transition: 0.5s;
    padding: 1.6rem;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;

    :hover {
        background-color: rgba(0,153,223,0.8);
    }
    :focus {
        outline: none;
        box-shadow: none;
    }

    ${p => p.show ? css`
        bottom: 0;
  ` : css`
        bottom: -80px;
  `}
`



export default() =>{
    
    const [Visible, setVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {  
            setVisible(window.pageYOffset > 350)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })
    // console.log(Visible)
    

    return(
        <BtnUp show={Visible} onClick={() => scrollTo('#header')}><FontAwesomeIcon  style={{cursor:'pointer', color:'white', fontSize:'1.5rem'}} icon={faChevronUp}/></BtnUp>
    )
}



