import { useStaticQuery, graphql } from "gatsby"
export const useImagesHeader = () => {
  const allImages = useStaticQuery(
    graphql`
      query imagesFluid {
        lawyer: file(relativePath: { eq: "images/header/lawyer4.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1048) {
                # ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        logo: file(relativePath: { eq: "images/header/logo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 328) {
                # ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        logo_caption: file(relativePath: { eq: "images/header/logo_caption.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 341) {
                # ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        lawyer2: file(relativePath: { eq: "images/header/04_Front_NY.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1048) {
                # ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        lawyer_full: file(relativePath: { eq: "images/header/04_Front_NY2.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1048) {
                # ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        insta_icon: file(relativePath: { eq: "images/header/instagram.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 24) {
                # ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        panoramic: file(relativePath: { eq: "images/header/Panoramic.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 6000) {
                # ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        logo_full: file(relativePath: { eq: "images/header/logo_full.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 762) {
                # ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )
  return allImages
}