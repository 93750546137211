export const isBrowser = () => typeof window !== "undefined"

export const getShowNL = () =>
  isBrowser() && window.localStorage.getItem("showNL")
    ? JSON.parse(window.localStorage.getItem("showNL"))
    : {}

export const setShowNL = (scrollArgs) =>{
  if(isBrowser()){
    window.localStorage.setItem("showNL", JSON.stringify(scrollArgs))
  }
}

  