//import React, { PureComponent} from "react"
import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { Link, navigate } from "gatsby"
import { css } from "@emotion/core"
//import { getUser, isLoggedIn, logout } from "../services/auth"
import SideNav from './sidebar'
// import ML from './moving-letters'
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Actionbutton } from '../components/common'


import { Helmet } from "react-helmet"
import { FaFacebook } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import instaLogo from '../images/header/instagram.svg'
import { useImagesHeader } from "../hooks/use-images-header"



// import { getMenuItem, setMenuItem } from "../cookies/submenu_cookie"




const TopContainer = styled.div`
  
  padding: 0.2rem;
  height: max-content;
  background-color: white;
  width:100%;
  line-height: 160%;
  z-index: 4;
  position: absolute; 
  top: 0;

  transition: 0.5s;

  P{
    color: #333;
  }
  overflow: auto;


  ${p => p.sticky ? css`
    position: fixed;
    top: 0;
    box-shadow: 0px 10px 15px rgba(150, 150, 150, 0.5);
    ${p.options ? css`
      box-shadow: none;
    ` : css`
    `}
      ${p.show ? css`
      top: 0;
    ` : css`
      position: fixed;
      top: -400px;
    `}
  ` : css`

      ${p.options ? css`
        box-shadow: none;
      ` : css`
      `}
      ${p.abs ? css`
        position: absolute; 
        box-shadow: 0px 10px 15px rgba(150, 150, 150, 0.5);
        top: 0;
          
    ` : css`
    `}
  `}
`
const MenuItem = styled.li`
    display: inline;
    margin-right: 2rem;
    padding: 0.1rem;
    margin-top: 0;
    margin-bottom: 0;
    color: #333;
    text-align: center;

    @media (max-width: 1100px) {
      display: none;
      }
`

const Menu = styled.ul`
    list-style: none;
    float: right;
    display: inline-block;
    margin-top: 0.7rem;
    margin-bottom: 0;
    right:0;
    padding-top: 1px;
    transition: 0.1s;
    font-size: 1.3rem;

    a{
      text-decoration: none;
      color: #333;
      box-shadow: none;
      margin-top: 0;
      margin-bottom: 0;

      @media (max-width: 1100px) {
        display: none;
        }
    }

    ${p => p.sticky ? css`
  ` : css`
      @media (max-width: 500px) {
          top: 130px;
      }
  `}
  
`
const SideScroll = styled.li`
  padding: 0.5rem;
  margin-right: 2rem;
  color: #333;
  display: inline-block;
  white-space: nowrap;
  font-size: 1.3rem;
  cursor: pointer;
  text-decoration: none;

  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 1100px) {
    display: none;
    }

    :after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: #214c9b;
      transition: width .3s;

        ${p => p.line ? css`
          width: 100%;
        ` : css`
        `}
    }
    :hover::after {
      width: 100%;
    }
`

const MenuItemResp = styled.li`
    display: block;
    margin-right: 2rem;
    margin-top: 0.8rem;

    @media (min-width: 1100px) {
      display: none;
    }
    cursor: pointer;
    color: #333;
    font-size: 1.9rem;
`

const LinkOut = styled.a`
  box-shadow: none;
  color: #333;
  display: inline;
  white-space: nowrap;
  cursor: pointer;
  width:70%;
`

const AnimatedBorder = styled.span`
  
  display: inline-block;

  :after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #214c9b;
    transition: width .3s;
    
    ${p => p.line ? css`
      width: 100%;
    ` : css`
    `}
  }
  :hover::after {
    width: 100%;
  }
`
// rgba(0, 0, 0, 0.8);
// #0099df
// background-color: rgba(33, 76, 155, 1);

const OptionsBar = styled.div`
  background-color: #007ab3;
  transition: 0.5s;
  overflow: none;
  padding: 1rem;
  width: 100%;
  max-width: 100%;
  position: absolute;
  z-index: 4;
  top: 51px;

  @media (max-width: 1100px) {
    display: none;
  }

  ${p => p.options ? css`
    
    overflow: hidden;
      ${p.IS ? css`
        height: 70px;        
      ` : css`
        height: 40px;        
      `}
      ${p.sticky ? css`
        position: fixed;
        ${p.show ? css`
          top: 51px;
        ` : css`
          position: fixed;
          top: -400px;
        `}
    ` : css`
    `}
  ` : css`
      height: 0px;
      padding: 0;
  `}
`

const SubmenuWrapper = styled.div`
  width: 90%;
  max-width: 100%;
  margin: auto;
  text-align: center;
  
`
const SubmenuDivider = styled.span`
  color: white;
  ${p => p.index == p.length ? css`
      display: none;
    ` : css`
    `}
`

const IconContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;
  
  @media (max-width: 1100px) {
    display: none;
    }
`

const MItem = styled.button`
  box-shadow: none;
  background-color: transparent;
  border: none;
  outline: 0;
  display: inline;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: white;

  ${p => p.id === 'true' ? css`
      ${p.name == p.iService ? css`
        color:  #dfb600;
      ` : css`
      `}
    ` : css`
      ${p.name-1 == p.perDiem ? css`
      ` : css`
      `}
    `}
  :hover{
    color:  #dfb600;
  }

`

// box-shadow: 0px 10px 15px rgba(150, 150, 150, 0.5);
const ConsultationCont = styled.div`
  background-color: transparent;
  height: 50px;
  padding: 1rem 2rem;
  z-index: 3;
  color: #888;
  width: max-content;
  position: absolute;
  top: 85px;
  right: 5vw;
  transition: 0.5s;
  
  @media (max-width: 400px) {
    right: 3vw;
  }
  ${p => p.sticky ? css`
    z-index: 4;
    ${p.show ? css`
      position: fixed;
      top: 60px;
    ` : css`
      top: -400px;
    `}
    ` : css`
    top: calc(3vw + 50px);
    ${p.abs ? css`
      z-index: 4;
    ` : css`
      
    `}
    `}

`

const ListLink = props => (
  <MenuItem>
    <Link onMouseOver={props.openOptions} to={props.to} style={{textDecoration:'none', color:'#333', boxShadow:'none', paddingLeft:'0.1rem', width:'max-content'}} activeStyle={{border:'2px solid #214c9b', borderTopStyle:'none', borderLeftStyle:'none', borderRightStyle:'none'}}><AnimatedBorder line={props.line}>{props.children}</AnimatedBorder></Link>
  </MenuItem>
)

const Submenu = props => (
  <SubmenuWrapper>
    <ul style={{marginBottom:'0', maxWidth:'100%', marginLeft:'auto'}}> 
      {props.Arr.map((element, index) => (
          <li key={index} style={{fontSize:'1.3rem', color: 'white', marginRight:'1rem', display:'inline-block', width:'max-content'}}><MItem iService={props.iService} perDiem={props.perDiem} name={index+1} id={props.IS.toString()} onClick={props.pickService}>{element}</MItem><SubmenuDivider index={index} length={props.Arr.length-1}>&nbsp;&nbsp;&nbsp;&nbsp;|</SubmenuDivider></li>
        ))}
    </ul>
  </SubmenuWrapper>
)

let per_diem_arr = ['Motions to Reopen', 'Legal Briefs', 'Immigration Court Representation', 'Interview Representation']
let services_arr = ['Asylum, WR, CAT', 'Deportation & Removal Defense', 'Board of Immigration Appeals & Federal Review', 'Motions to Reopen', 'Adjustment of Status', 'Removal of Conditions', 'Domestic Violence', 'Cooperating With Law Enforcement', 'Victims of Human Trafficking']

let originalPos = 600

// class HeaderMenu extends PureComponent {
const HeaderMenu  = (props) => {
  let Images = useImagesHeader()

  const [isMobileNavFolded, setisMobileNavFolded] = useState(true);
  const [options, setOptions] = useState(false);
  const [perDiem, setPerDiem] = useState(false);
  const [IS, setIS] = useState(false);
  const [ServicesArr, setServicesArr] = useState([]);
  const [showMenu, setShowMenu] = useState(!props.isSticky);

  
  const toggleMobileNav = () => {
    setisMobileNavFolded(!isMobileNavFolded)
  }
  const toggleState = () => {
    setisMobileNavFolded(isMobileNavFolded)
  }

  const onRouteChange = () => {
    setisMobileNavFolded(true)
  }
  const handleScroll = () => {  
     setShowMenu(window.pageYOffset > originalPos)
  }
  
  const scrollLinkFn = (evt) =>{
      setOptions(false)
      setServicesArr([])
      setIS(false)
      setPerDiem(false)
      
    if(scrollTo(evt.target.attributes.name.nodeValue) == false){
      navigate('/'+evt.target.attributes.name.nodeValue)
    }else{
      scrollTo(evt.target.attributes.name.nodeValue)
    }
  }

  const openPerdiem = (evt) =>{
      setOptions(true)
      setServicesArr(per_diem_arr)
      setIS(false)
      setPerDiem(true)
  }
  const openIS = (evt) =>{
      setOptions(true)
      setServicesArr(services_arr)
      setIS(true)
      setPerDiem(false)
  }
  const closeOptions = () =>{
      setOptions(false)
      setServicesArr([])
      setIS(false)
      setPerDiem(false)
  }

  // componentDidMount = () =>{
  //   if(props.isSticky){
  //     window.addEventListener('scroll', handleScroll)
  //   }
  // }

  useEffect(() => {
    if(props.isSticky){
      window.addEventListener('scroll', handleScroll)
    }
  }, [])
  return(
    // const { isMobileNavFolded } = isMobileNavFolded
      <div>
        <Helmet title="felawpllc.com" defer={false} >
        {/* <meta name='viewport' 
     content='width=device-width, initial-scale=1.0, maximum-scale=1.0, 
     user-scalable=0' /> */}
        </Helmet>
        <TopContainer id='navbar_wrapper' abs={props.abs} options={options} sticky={props.isSticky} show={showMenu} id='header'>    
                {/* <Link id='navbar_logo' style={{textDecoration:'none', marginTop:'10px', marginLeft:'1.5rem', boxShadow:'none', color:'#214c9b', fontSize:'26px', display:'inline-block'}} to="/">FeodoroffLaw</Link> */}
                <Link id='navbar_logo' to="/">
                  <Img css={css`
                    display: block;
                    float: left;
                    @media (max-width: 600px) {
                        display: none;                            
                    }
                    `}id='s_who_we_are_img' style={{width: '80px', marginLeft:'1.5rem'}} fluid={Images["logo"].childImageSharp.fluid} />
                  <Img css={css`
                    width: 130px;
                    @media (max-width: 600px) {
                      margin-top: 4px;
                      width: 150px;                          
                    }
                    `} id='s_who_we_are_img' style={{float:'left', display:'block', marginLeft:'1.5rem', marginTop:'0.7rem'}} fluid={Images["logo_caption"].childImageSharp.fluid} />
                </Link>
                <Menu id='navbar_menu' sticky={props.isSticky}>
                      <ListLink id='navbar_link1' openOptions={closeOptions} to="/who_we_are">Who We Are</ListLink>
                      {/* <ListLink line={this.state.IS} id='navbar_link2'  openOptions={this.openIS} to="/services">Immigration Services</ListLink> */}
                      <AnchorLink to="/#is"><SideScroll line={IS} id='navbar_link5' onMouseOver={openIS} name='#is' onClick={closeOptions}>Immigration Services</SideScroll></AnchorLink>
                      <ListLink to="/per_diem" line={perDiem} openOptions={openPerdiem} name='#per_diem'>Per Diem Services</ListLink>
                      <ListLink id='navbar_link4' openOptions={closeOptions} to="/newsletter">Newsletter</ListLink>
                      <AnchorLink to="/#reviews"><SideScroll id='navbar_link5' onMouseOver={closeOptions} name='#reviews'>Reviews</SideScroll></AnchorLink>
                      <AnchorLink to="/#contact"><SideScroll id='navbar_link6' onMouseOver={closeOptions} name='#contact'>Contact Us</SideScroll></AnchorLink>
                      <LinkOut id='navbar_link7' onMouseOver={closeOptions} href='https://www.mycase.com/login'><AnimatedBorder>MyCase</AnimatedBorder></LinkOut>
                      <MenuItemResp css={css`
                    
                    @media (min-width: 600px) {
                      margin-top: 0.5rem;
                                          
                    }
                    `}id='navbar_responsive' onClick={toggleMobileNav}><FontAwesomeIcon icon={faBars}/></MenuItemResp>
                      <a href='https://www.facebook.com/Felawpllc'><IconContainer style={{marginLeft:'2rem'}}><FaFacebook size={20} style={{position: 'absolute', cursor:'pointer', color:'#214c9b', top:'5px'}}/></IconContainer></a>
                      <a href='https://www.instagram.com/immigration_attorney_nikolay/'><IconContainer style={{width:'24px'}}><img style={{position: 'absolute', cursor:'pointer', top:'5px', width: "20px"}} src={instaLogo} /></IconContainer></a>
                      <a href='https://www.tiktok.com/@attorney_nikolay?lang=en'><IconContainer style={{marginLeft:'0.2rem', marginRight:'1rem'}}><SiTiktok size={20} style={{position: 'absolute', cursor:'pointer', color:'black', top:'5px'}}/></IconContainer></a>                
                </Menu>
        </TopContainer>
        <SideNav
            id='navbar_sidebar'
            {...props}
            isMobileNavFolded={isMobileNavFolded}
            onMobileNavToggle={toggleMobileNav}
        />
        <ConsultationCont sticky={props.isSticky} show={showMenu} abs={props.abs}>
          <Actionbutton css={css`
                    background-color: rgba(30, 134, 66, 0.7);
                    border-color: transparent;
                    :hover{
                      background-color: rgba(30, 134, 66, 1);
                      border-color: rgba(30, 134, 66, 1);
                    }
                    `} style={{ color:'white', fontSize:'2rem', width:'max-content', padding:'0.8rem 3rem'}} onClick={props.consulOpen}>Consultation</Actionbutton>
        </ConsultationCont>
        <OptionsBar show={showMenu} sticky={props.isSticky} abs={props.abs} options={options} IS={IS} onMouseLeave={closeOptions}>
          <Submenu iService={props.iService} perDiem={props.perDiem} IS={IS} pickService={props.pickService} Arr={ServicesArr}/>
        </OptionsBar>
      </div>
    )
  
}
  export default HeaderMenu
  
  // Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
  
