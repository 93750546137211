import React from "react"
import styled from "@emotion/styled"

// import { css } from "@emotion/core"
// import { Link } from "gatsby"
import { useImagesHeader } from "../hooks/use-images-header"
import Img from "gatsby-image"
import { SiLinkedin } from 'react-icons/si';
import { TiSocialFacebookCircular } from 'react-icons/ti';
import { SiTiktok } from 'react-icons/si';
import instaLogo from '../images/header/instagram.svg'



const Bg = styled.div`
   background-color: #2a2a2a;
   width: 100%;
   color: white;
   z-index: -1;
   overflow: auto;
   padding: 3rem;
   
   font-size: 1.5rem;

   h1{
       color: white;
       text-align: center;
       margin-top: 4.6vw;
   }
   p{
        @media (max-width: 700px) {
            font-size: 0.8rem;
        }
    }   
`
const SubContainer = styled.div`
    margin: auto;
    width: 100%;
    max-width: 1100px;
`
const LogoContainer = styled.div`
    float: left;
    width: 18%;
    border: 2px solid white;
    border-top-style: none;
    border-bottom-style: none;
    border-left-style: none;

    @media (max-width: 800px) {
        display: none;                       
      }
`
const InfoContainer = styled.div`
    float: left;
    width: 75%;
    margin-left: 3rem;
    margin-top: 2%;
   
    @media (max-width: 800px) {
        p{
            font-size: 1.4rem;
            margin-bottom: 2rem;
        }

        float: none;
        width: 100%;
        margin: auto;
        margin-top: 2rem;
    }
`

let Images =  {}
// let animationNames = ['','',''] 



const Footer = (props) =>{
    // const [rendering, setRendering] = useState(false);

    Images = useImagesHeader()

    return(
        
            <Bg>
                <SubContainer>
                    <LogoContainer><Img id='logo_footer' fluid={Images["logo_full"].childImageSharp.fluid} /></LogoContainer>
                    <InfoContainer> 
                        <div style={{margin:'auto', width:'max-content', maxWidth:'100%', overflow:'auto'}}>
                            {/* <a href='https://www.linkedin.com/in/nikolay-nick-feodoroff-aa59b7a5/'><SiLinkedin size={32} style={{marginLeft:'10px', backgroundColor:'white', width:'32px', borderRadius:'4px', display:'block', float:'left', cursor:'pointer', color:'#0273b1'}}/></a> */}
                            <a href='https://www.facebook.com/Felawpllc'><TiSocialFacebookCircular size={40} style={{marginTop: '-0.4rem', marginLeft:'10px', width:'40px', display:'block', float:'left', cursor:'pointer', color:'#214c9b'}}/></a>
                            <a href='https://www.tiktok.com/@attorney_nikolay?lang=en'><SiTiktok size={32} style={{marginLeft:'10px', width:'32px', display:'block', float:'left', cursor:'pointer', color:'white'}}/></a>
                            <a href='https://www.instagram.com/immigration_attorney_nikolay/'><img style={{marginLeft:'15px', display:'block', float:'left', cursor:'pointer', width: "32px"}} src={instaLogo} /></a>
                        </div>
                        <br></br>
                        <div style={{margin:'auto', width:'max-content', maxWidth:'100%'}}>
                            <p style={{textAlign:'center'}}>(718)-715-1667 &nbsp;&nbsp; |  &nbsp;&nbsp; Fax: 929-489-8253  &nbsp;&nbsp; |  &nbsp;&nbsp; 147 Prince Street, Brooklyn, N.Y. 11201, United States  &nbsp;&nbsp; | &nbsp;&nbsp; info@felawpllc.com</p>
                            <p style={{margin:'auto', width:'max-content'}}>FelawPLLC All Rights Reserved 2020</p>
                            <p style={{margin:'auto', width:'max-content'}}>Attorney Advertisement</p>
                        </div>
                    </InfoContainer>
                </SubContainer>
            </Bg>   
       
    )
}

export default Footer
// Tsar1812!