import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link, navigate } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { TiSocialFacebookCircular } from 'react-icons/ti';
import { SiTiktok } from 'react-icons/si';
import instaLogo from '../images/header/instagram.svg'
// import Img from "gatsby-image"


//import { getUser, isLoggedIn, logout } from "../services/auth"


const SideNav = styled.div`
    height: 100%; 
    position: fixed;
    z-index: 50; 
    top: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6); 
    overflow-x: hidden; 
    padding-top: 5rem;
    transition: 0.5s; 
    ${p => p.open ? css`
      width: 260px;
  ` : css`
      width: 0px;
  `}

`

const SideNavCover = styled.div`
    transition: 0.5s; 
    width:100%;
    height:100%;
    left:0;
    position: fixed;
    top: 0; 
    z-index: 49; 
    display:block;
    ${p => p.open ? css`
      visibility: visible;
      background-color: rgba(0,0,0,0.6);
]     
  ` : css`
      background-color: rgba(0,0,0,0);
      visibility: hidden;
  `}
`
const SideBarItem = styled.li`
    padding: 2px 2px 2px 5px;
    color: #ffffff;
    display: block;
    transition: 0.3s;
    white-space: nowrap;
    max-width: 90%;


`
const SideMenu = styled.ul`
    list-style: none;
    a{
        font-size: 2rem;
        color: white;
        padding: 0.3rem;
        box-shadow: none;
        margin: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
      }
      li{
        margin-bottom: 1.2rem;
        font-size: 2rem;
      }
`
const SideScroll = styled.li`
  padding: 2px 2px 2px 5px;
  color: #ffffff;
  display: block;
  transition: 0.3s;
  white-space: nowrap;
  font-size: 2rem;
  cursor: pointer;
  width: max-content;
  max-width: 90%;
  padding: 0;
  padding-bottom: 0.5rem;
  margin-left: 0.5rem;
`
const LinkOut = styled.a`
  font-size: 2rem;
  box-shadow: none;
  color: #ffffff;
  display: block;
  white-space: nowrap;
  cursor: pointer;
  width: max-content;
  padding-bottom: 0.5rem;
  margin-left: 0.5rem;
  text-decoration: none;
`
const Anchor = styled.span`
  transition: 0.3s;
  white-space: nowrap;
  font-size: 2rem;
  cursor: pointer;
  width: max-content;
  max-width: 90%;
  padding-bottom: 0.5rem;
  text-decoration: none;
  display: inline-block;
  text-align: left;
  margin-bottom: 1rem;
  margin-left: -0.2rem;
`

const IconContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 0;
`

const LinkSide = props => (
    <SideBarItem>
      <Link to={props.to} style={{textDecoration:'none', marginLeft:'-0.2rem', color:'#FFFFFF', padding:'0.3rem', boxShadow:'none', fontSize:'2rem', width:'100%'}} activeStyle={{ color:'#0099df'}}>{props.children}</Link>
    </SideBarItem>
  )

  const SideNavbar = props => {
    const {
      isMobileNavFolded,
      onMobileNavToggle,
    } = props

    const scrollLinkFn = (evt) =>{
    
      if(scrollTo(evt.target.attributes.name.nodeValue) == false){
        navigate('/'+evt.target.attributes.name.nodeValue)
      }else{
        scrollTo(evt.target.attributes.name.nodeValue)
      }
    }
  
    return ( 
      <>
        <SideNavCover onClick={onMobileNavToggle} open={!isMobileNavFolded}></SideNavCover>
        <SideNav onClick={onMobileNavToggle} open={!isMobileNavFolded}>
          <SideMenu>      
            <LinkSide to="/">FEODOROFF LAW</LinkSide>              
            <LinkSide to="/who_we_are">Who We Are</LinkSide>
            <AnchorLink to="/#is"><Anchor style={{marginBottom:'1.2rem'}}>Immigration Services</Anchor></AnchorLink>
            <LinkSide to="/per_diem" name='#per_diem' style={{padding:'0'}}>Per Diem Services</LinkSide>
            <LinkSide to="/newsletter">Newsletter</LinkSide>
            <AnchorLink to="/#reviews"><Anchor name='#reviews'>Reviews</Anchor></AnchorLink>
            <br></br>
            <AnchorLink to="/#contact"><Anchor>Contact Us</Anchor></AnchorLink>
            <LinkOut id='navbar_link7' href='https://www.mycase.com/login'>MyCase</LinkOut>
            <a style={{marginLeft:'0'}} href='https://www.facebook.com/Felawpllc'><IconContainer style={{marginLeft:'0.4rem',marginTop:'1.6rem'}}><TiSocialFacebookCircular size={26} style={{position: 'absolute', cursor:'pointer', color:'#6c94e0', top:'2px'}}/></IconContainer></a>
            <a style={{marginLeft:'-0.5rem'}} href='https://www.instagram.com/immigration_attorney_nikolay/'><IconContainer style={{width:'24px', marginLeft:'1em'}}><img style={{position: 'absolute', cursor:'pointer', top:'5px', width: "20px"}} src={instaLogo} /></IconContainer></a>
            <a style={{marginLeft:'0'}} href='https://www.tiktok.com/@attorney_nikolay?lang=en'><IconContainer style={{marginLeft:'0.2rem', marginRight:'1rem'}}><SiTiktok size={20} style={{position: 'absolute', cursor:'pointer', color:'white', top:'5px'}}/></IconContainer></a>     
          </SideMenu>
        </SideNav>
        
     </>
    )
  }
  
  export default SideNavbar